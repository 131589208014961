import React, { useState } from 'react';
import _ from 'lodash';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { attachTimeToDate, sendOfferAcceptedByGuestEmail } from '../../util/misc';
import { GeneralActionButton } from './SysMsgComponents';
import FileComponent from './FileComponent';
import { ReactComponent as PencilIcon } from './icons/pencil.svg';
import { ReactComponent as TickIcon } from './icons/tick.svg';
import { ReactComponent as XIcon } from './icons/x.svg';
import IconSpinner from '../IconSpinner/IconSpinner';
import { DeclineOfferModal } from '../../components'
import css from './ActivityFeed.module.css';

const DateFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
};

const ExpiryInDays = config.offerExpiryPeriodInDays;

const OfferComponent = (props) => {
    const {
        intl,
        offer,
        isLastOffer,
        isInquiry,
        offerFile,
        role,
        currentTransaction,
        listingId,
        customerId,
        offerBreakdown,
        activity,
        sendOfferInProgress,
        onUpdateOffer,
        onAcceptFileOffer,
        onHandleG4,
        lineItems,
        onSubmitBookingRequest,
        customerName,
        providerName,
        onSetOfferForUpdate,
    } = props;

    const [declineModalOpen, setDeclineModalOpen] = useState(false);

    const {
        _id,
        offerType,
        offerStatus,
        createdAt,
        reactivatedAt,
    } = offer;

    const txId = currentTransaction.id;

    const isFileOffer = offerType == "file";
    const isProviderRole = role == "provider";

    const startDateToTake = reactivatedAt ? reactivatedAt : createdAt;
    const clonedCreationDate = _.cloneDeep(new Date(startDateToTake));
    const expiryDate = new Date(clonedCreationDate.setDate(clonedCreationDate.getDate() + ExpiryInDays));
    const formattedExpiryDate = expiryDate.toLocaleDateString('en-US', DateFormatOptions);

    const isOfferExpired = expiryDate - new Date() <= 0;
    const isOfferAccepted = offerStatus == "accepted";
    const isOfferDeclined = offerStatus == "declined";
    const showCustomerActions =
        !isOfferAccepted &&
        !isOfferDeclined &&
        isLastOffer &&
        isInquiry;
    const showReactivateButton =
        isProviderRole &&
        isOfferExpired &&
        showCustomerActions;

    const onReactivateOffer = () => {
        onUpdateOffer({
            tx_id: txId.uuid,
            offer_id: _id,
            reactivatedAt: new Date(),
        });
    };

    const formatOfferAndCheckout = () => {
        const startDate = attachTimeToDate(new Date(offer.startDate), offer.startTime);
        const endDate = attachTimeToDate(new Date(offer.endDate), offer.endTime);

        if (startDate > new Date()) {
            const theData = {
                offerId: offer._id,
                startDate,
                endDate,
                noOfGuest: offer.noOfGuests,
                addOns: offer.addons,
                activity: activity,
                removeFee: offer.disableRentalFee,
                allAddons: offer.addons,
                offerPrice: offer.totalPrice,
            };

            if (offer.offerType == "file") {
                theData.offerType = "file";
                theData.listingId = listingId;
                onAcceptFileOffer(theData, txId);
            } else {
                sendOfferAcceptedByGuestEmail({
                    providerId: currentTransaction.provider.id.uuid,
                    listingTitle: currentTransaction.listing.attributes.title,
                    providerName: currentTransaction.provider.attributes.profile.displayName,
                    customerName: currentTransaction.customer.attributes.profile.displayName,
                    transactionId: txId.uuid,
                });
                onSubmitBookingRequest(theData);
                onHandleG4({
                    txId: txId,
                    isFile: false,
                    activity,
                    lineItems,
                });
            };
        } else {
            alert("Invalid booking dates");
        };
    };

    return <div className={css.offerRoot}>
        <div className={css.offerHeader}>
            <FormattedMessage
                id={isProviderRole ? "OfferComponent.providerTitle" : "OfferComponent.customerTitle"}
                values={{ customerName, providerName }}
            />
            {isProviderRole && !isOfferExpired && showCustomerActions ? <PencilIcon onClick={onSetOfferForUpdate} /> : null}
            {showReactivateButton ? <a
                className={css.reactivateOfferLink}
                onClick={onReactivateOffer}
            >
                <FormattedMessage id="OfferComponent.reactivateOffer" />
            </a> : null}
        </div>

        <div className={css.offerExpiry}>
            <FormattedMessage
                id="OfferComponent.expiryText"
                values={{ date: formattedExpiryDate }}
            />
        </div>

        {isFileOffer ? <div className={css.offerFile}>
            {offerFile ? <FileComponent {...offerFile} /> : "File not found"}
        </div> : offerBreakdown ? <div className={css.offerBreakdown}>
            {offerBreakdown}
        </div> : null}

        <div className={css.offerNote}>
            <FormattedMessage
                id={isProviderRole ? "OfferComponent.noteToProvider" : "OfferComponent.noteToCustomer"}
                values={{ customerName, ExpiryInDays }}
            />
        </div>

        {isProviderRole || !showCustomerActions || isOfferExpired || sendOfferInProgress ? null : <div className={css.offerActions}>
            <GeneralActionButton
                type="success"
                icon={<TickIcon />}
                textId="OfferComponent.acceptOfferBtn"
                onClick={formatOfferAndCheckout}
            />

            <GeneralActionButton
                type="decline"
                icon={<XIcon />}
                textId="OfferComponent.declineOfferBtn"
                onClick={() => setDeclineModalOpen(true)}
            />

            <DeclineOfferModal
                isOpen={declineModalOpen}
                onClose={() => setDeclineModalOpen(false)}
                intl={intl}
                onManageDisableScrolling={() => { }}
                onSubmit={(e) => {
                    const { declineOption, reasonOfDecline } = e;
                    onUpdateOffer({
                        tx_id: txId.uuid,
                        offer_id: _id,
                        offerStatus: "declined",
                        action: "decline",
                        user_id: customerId.uuid,
                        decline_reason: declineOption && declineOption !== "other" ? declineOption : reasonOfDecline,
                    })
                }}
            />
        </div>}

        {sendOfferInProgress && showCustomerActions ? <IconSpinner /> : null}

        {isOfferAccepted ? <div style={{ color: "#2ECC71" }}>
            <FormattedMessage id="OfferComponent.offerWasAccepted" />
        </div> : isOfferDeclined ? <div style={{ color: "#D10000" }}>
            <FormattedMessage id="OfferComponent.offerWasDeclined" />
        </div> : isOfferExpired ? <div style={{ color: "#D10000" }}>
            <FormattedMessage id="OfferComponent.offerHasExpired" />
        </div> : null}
    </div>
};

export default OfferComponent;
