import _ from 'lodash';
import config from '../config';
import { sendSGMail } from './api';
import {
    formatMoney,
    unitDivisor,
    convertMoneyToNumber,
    convertUnitToSubUnit,
} from './currency';
import { userDisplayNameAsString } from './data';
import { types as sdkTypes } from './sdkLoader';
import Decimal from 'decimal.js';

const { Money } = sdkTypes;

export const BookingDataThroughSignUpKey = "BDTSUK";
export const BookingDataTempCheckKey = "BDTCK";

export const DeclineOrCancelOptions = [
    "Want some details changed",
    "Price too high",
    "Found a better option",
    "Changed plans",
];

const generateFullDayHours = () => {
    return Array(24).fill('').map((e, i) => `${(i + 1).toString().padStart(2, "0")}:00`);
};

export const generateStartTimes = (date) => {
    const allHours = generateFullDayHours();
    const clonedDate = date ? _.cloneDeep(date) : null;
    const zeroHoursDate = clonedDate ? new Date(clonedDate.setHours(0)) : null;

    if (zeroHoursDate && zeroHoursDate > new Date()) {
        return allHours;
    } else {
        const currentHour = new Date().getHours();
        const nextHour = currentHour + 1;
        return _.slice(allHours, nextHour, 24);
    };
};

export const generateEndTimes = (startDate, endDate, startHour) => {
    const allHours = generateFullDayHours();
    if (startDate && endDate && endDate > startDate) {
        return allHours;
    } else {
        const nextHour = startHour + 1;
        return _.slice(allHours, nextHour, 24);
    };
};

const generateHourlyFormatOfTimeStamp = (stamp) => {
    const hour = new Date(Number(stamp)).getHours();
    const correctHour = hour == 0 ? 24 : hour;
    return `${correctHour.toString().padStart(2, "0")}:00`
};

export const formatRawDataToAddonFormData = (rawData) => {
    const {
        bookingStartDate,
        bookingEndDate,
        bookingStartTime,
        bookingEndTime,
        noOfGuest,
    } = rawData;

    const clonedStartDate = bookingStartDate.date ? _.cloneDeep(bookingStartDate.date) : null;
    const clonedEndDate = bookingEndDate.date ? _.cloneDeep(bookingEndDate.date) : null;

    return {
        startDate: { date: new Date(new Date(clonedStartDate).toDateString()) },
        endDate: { date: new Date(new Date(clonedEndDate).toDateString()) },
        startTime: generateHourlyFormatOfTimeStamp(bookingStartTime),
        endTime: generateHourlyFormatOfTimeStamp(bookingEndTime),
        noOfGuests: Number(noOfGuest),
    }
};

export const formatDBDataToAddonFormData = (dbData) => {
    const {
        startDate,
        endDate,
        startTime,
        endTime,
        noOfGuests,
        totalPrice,
        tax,
        disableRentalFee,
        attachedFileId,
    } = dbData;

    return {
        startDate: { date: new Date(startDate) },
        endDate: { date: new Date(endDate) },
        startTime,
        endTime,
        noOfGuests,
        spaceRentalPrice: totalPrice / 100,
        taxAmount: tax / 100,
        disableSpaceRentalPrice: disableRentalFee,
        attachedFileId,
    }
};

export const attachTimeToDate = (date, time) => {
    const clonedDate = _.cloneDeep(new Date(date));
    const hours = time.split(":")[0];
    return new Date(clonedDate.setHours(Number(hours)));
};

export const getValidOffer = (offers = []) => {
    if (offers.length == 0) {
        return null;
    } else {
        const ExpiryInDays = config.offerExpiryPeriodInDays;

        const lastOffer = offers[offers.length - 1];
        const lastOfferCreationDate = lastOffer.createdAt;
        const clonedCreationDate = _.cloneDeep(new Date(lastOfferCreationDate));
        const expiryDate = new Date(clonedCreationDate.setDate(clonedCreationDate.getDate() + ExpiryInDays));

        if (expiryDate > new Date()) {
            return lastOffer;
        } else {
            return null;
        };
    };
};

export const formatOfferDate = (date) => {
    const dString = new Date(date).toLocaleDateString('en-GB');
    const dTime = new Date(date).toLocaleTimeString('en-US');
    return `${dString} ${dTime}`;
};

export const sendInquiryAcceptedByHostEmail = async (params) => {
    const {
        customerId,
        listingTitle,
        providerName,
        customerName,
        transactionId,
        formattedStartDate,
        formattedEndDate,
    } = params;

    await sendSGMail({
        userId: customerId,
        subject: `Good News! ${providerName} accepted your inquiry for ${listingTitle} - Confirm Now`,
        template: "inquiry-accepted-by-host",
        emailParams: {
            providerName,
            customerName,
            listingTitle,
            transactionId,
            formattedStartDate,
            formattedEndDate,
        },
        text: `Your booking inquiry for ${listingTitle} has been accepted by the host.`,
    });
};

export const sendOfferAcceptedByGuestEmail = async (params) => {
    const {
        providerId,
        listingTitle,
        providerName,
        customerName,
        transactionId,
    } = params;

    await sendSGMail({
        userId: providerId,
        subject: `Individual Offer Accepted for ${listingTitle}`,
        template: "offer-accepted-by-guest",
        emailParams: {
            providerName,
            customerName,
            listingTitle,
            transactionId,
        },
        text: `${customerName} has accepted your individual offer for ${listingTitle}.`,
    });
};

export const sendOfferCreatedEmail = async (params) => {
    const {
        intl,
        offer,
        listingTitle,
        customerId,
        providerName,
        customerName,
        payinTotalAmount,
        fileURL,
    } = params;

    const {
        endDate,
        endTime,
        startDate,
        startTime,
        offerType,
        totalPrice,
        tx_id,
    } = offer;

    const isDefaultOffer = offerType == "default";

    const formattedStartDate = formatOfferDate(attachTimeToDate(new Date(startDate), startTime));
    const formattedEndDate = formatOfferDate(attachTimeToDate(new Date(endDate), endTime));
    const daysToExpiry = config.offerExpiryPeriodInDays;

    const totalToTake = isDefaultOffer ? payinTotalAmount : totalPrice;
    const payinTotal = formatMoney(intl, new Money(totalToTake, 'CHF'));

    if (isDefaultOffer) {
        await sendSGMail({
            userId: customerId,
            subject: `${providerName} has created an individual offer for you!`,
            template: "offer-created",
            emailParams: {
                providerName,
                customerName,
                listingTitle,
                transactionId: tx_id,
                formattedStartDate,
                formattedEndDate,
                daysToExpiry,
                payinTotal,
            },
            text: `An individual offer has been created for your inquiry about ${listingTitle}.`,
        });
    } else {
        await sendSGMail({
            userId: customerId,
            subject: `${providerName} Has Uploaded a Quote for You!`,
            template: "file-offer-created",
            emailParams: {
                providerName,
                customerName,
                listingTitle,
                transactionId: tx_id,
                formattedStartDate,
                formattedEndDate,
                daysToExpiry,
                payinTotal,
                fileURL,
            },
            text: `${providerName} has uploaded a quote for your inquiry about ${listingTitle}.`,
        });
    };
};

export const addHoursToDate = (hours, date = new Date()) => {
    const clonedDate = _.cloneDeep(new Date(date));
    const newDate = new Date(clonedDate.setHours(clonedDate.getHours() + hours));
    return newDate;
};

const formatG4Date = (date) => {
    if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    } else {
        return "";
    };
};

const estimatedTotalPrice = (lineItems = []) => {
    const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
        const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
        return new Decimal(numericPrice).add(sum);
    }, 0);

    const currency =
        lineItems[0] && lineItems[0].unitPrice
            ? lineItems[0].unitPrice.currency
            : config.currency;

    return !numericTotalPrice ? { amount: 0, currency: 'CHF' } : new Money(
        convertUnitToSubUnit(
            numericTotalPrice.toNumber(),
            unitDivisor(currency)
        ),
        currency
    );
};

export const getG4Params = ({
    event,
    ensuredAuthor,
    currentListing,
    rawBookingData,
    lineItems = [],
    addittional = {},
}) => {
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { title = "", publicData } = currentListing.attributes;
    const {
        clusters = [],
        addOns = [],
        location,
        squaremeter,
        totalCapacity,
    } = publicData || {};

    const {
        activity,
        bookingEndTime,
        bookingStartTime,
        noOfGuest,
    } = rawBookingData || {};

    const foundActivity = activity ? clusters.find(cl => cl.uuid == activity) : "";
    const activityName = foundActivity ? foundActivity.title : "";

    const bookingStartDate = bookingStartTime ? new Date(Number(bookingStartTime)) : "";
    const bookingEndDate = bookingEndTime ? new Date(Number(bookingEndTime)) : "";
    const duration = bookingStartDate ? Math.floor((bookingEndDate - bookingStartDate) / (1000 * 60 * 60)) : 0;

    const customerLineItems = lineItems?.filter((item) =>
        item?.includeFor?.includes('customer')
    );
    const payinTotal = estimatedTotalPrice(customerLineItems);

    const g4Params = {
        event,
        spaces: [{
            space_name: title,
            space_id: currentListing.id.uuid,
            space_address: location?.address,
            space_capacity: totalCapacity,
            space_size: Number(squaremeter),
        }],
        activities: clusters.map(cl => {
            const {
                maxNoOfGuest,
                title: clTitle,
                price,
                minBookingDuration,
            } = cl;
            return {
                active: true,
                name: clTitle,
                minimum_booking: Number(minBookingDuration),
                max_guests: Number(maxNoOfGuest),
                price: price && price.amount ? price.amount / 100 : "",
            };
        }),
        addons: addOns.map(add => {
            const {
                description,
                price: addPrice,
                title: addTitle,
                typeOfCharge,
            } = add;
            return {
                active: true,
                name: addTitle,
                description: description || "",
                price: addPrice && addPrice.amount ? addPrice.amount / 100 : "",
                type_of_charge: typeOfCharge,
            };
        }),
        booking: [{
            activity: activityName,
            guests: Number(noOfGuest),
            start_date: formatG4Date(bookingStartDate),
            end_date: formatG4Date(bookingEndDate),
            duration,
            price: payinTotal.amount / 100,
            currency: payinTotal.currency,
        }],
        host_id: ensuredAuthor.id.uuid,
        host_name: authorDisplayName,
        ...addittional,
    };

    return g4Params;
};

export const getG4ParamsFromTx = ({
    event,
    provider,
    listing,
    tx,
    booking,
    addittional = {},
    activity,
    lineItems = [],
}) => {
    const authorDisplayName = userDisplayNameAsString(provider, '');

    const { payinTotal: txPayinTotal, protectedData, metadata } = tx.attributes;
    const rawData = metadata.rawBookingData;

    const customerLineItems = lineItems?.filter((item) =>
        item?.includeFor?.includes('customer')
    );
    const payinTotal = txPayinTotal ? txPayinTotal : estimatedTotalPrice(customerLineItems);

    const { title = "", publicData } = listing.attributes;
    const {
        clusters = [],
        addOns = [],
        location,
        squaremeter,
        totalCapacity,
    } = publicData || {};

    const {
        activities,
        noOfGuest,
    } = protectedData || {};

    const {
        end: bookingEndDate,
        start: bookingStartDate,
    } = booking?.attributes || {};

    const activityFromProps = activity ? clusters.find(cl => cl.uuid == activity) : "";
    const foundActivity = activities ? clusters.find(cl => cl.title == activities) : "";
    const activityName = activityFromProps
        ? activityFromProps.title
        : foundActivity ?
            foundActivity.title
            : "";

    const {
        bookingEndTime: rawEndTime,
        bookingStartTime: rawStartTime,
        noOfGuest: rawNoOfGuest,
    } = rawData || {};

    const rawBookingStartDate = rawStartTime ? new Date(Number(rawStartTime)) : "";
    const rawBookingEndDate = rawEndTime ? new Date(Number(rawEndTime)) : "";
    const duration = bookingStartDate ? Math.floor((bookingEndDate - bookingStartDate) / (1000 * 60 * 60)) : rawBookingStartDate ? Math.floor((rawBookingEndDate - rawBookingStartDate) / (1000 * 60 * 60)) : 0;

    const g4Params = {
        event,
        spaces: [{
            space_name: title,
            space_id: listing.id.uuid,
            space_address: location?.address,
            space_capacity: totalCapacity,
            space_size: Number(squaremeter),
        }],
        activities: clusters.map(cl => {
            const {
                maxNoOfGuest,
                title: clTitle,
                price,
                minBookingDuration,
            } = cl;
            return {
                active: true,
                name: clTitle,
                minimum_booking: Number(minBookingDuration),
                max_guests: Number(maxNoOfGuest),
                price: price && price.amount ? price.amount / 100 : "",
            };
        }),
        addons: addOns.map(add => {
            const {
                description,
                price: addPrice,
                title: addTitle,
                typeOfCharge,
            } = add;
            return {
                active: true,
                name: addTitle,
                description: description || "",
                price: addPrice && addPrice.amount ? addPrice.amount / 100 : "",
                type_of_charge: typeOfCharge,
            };
        }),
        booking: [{
            activity: activityName,
            guests: Number(noOfGuest ? noOfGuest : rawNoOfGuest),
            start_date: formatG4Date(bookingStartDate ? bookingStartDate : rawBookingStartDate),
            end_date: formatG4Date(bookingEndDate ? bookingEndDate : rawBookingEndDate),
            duration,
            price: payinTotal.amount / 100,
            currency: payinTotal.currency,
        }],
        host_id: provider.id.uuid,
        host_name: authorDisplayName,
        ...addittional,
    };

    return g4Params;
};