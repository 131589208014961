import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ReactComponent as TickIcon } from './icons/tick.svg';
import { ReactComponent as XIcon } from './icons/x.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as PencilIcon } from './icons/pencil.svg';
import { ReactComponent as CircleXIcon } from './icons/circleX.svg';
import { ReactComponent as GreyCircleTick } from './icons/greyCircleTick.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import premiumBadge from './icons/premiumBadge.png';
import { IconSpinner, Tooltip } from '../../components';
import css from './ActivityFeed.module.css';

export const GeneralActionButton = (props) => {
    const { type, icon, textId, onClick, badge } = props;

    const style = {
        color: "#808080",
        backgroundColor: "#ffffff",
    };

    if (type == "success") {
        style.color = "#2ECC71";
    } else if (type == "decline") {
        style.color = "#D10000";
    } else if (type == "confirm") {
        style.color = "#ffffff";
        style.backgroundColor = "#30A64A";
    } else if (type == "confirmInverted") {
        style.color = "#30A64A";
        style.borderColor = "#30A64A";
    };

    return <div
        role="button"
        className={css.generalActionButton}
        onClick={onClick}
        style={style}
    >
        {icon ? icon : ""}
        <FormattedMessage id={textId} />
        {badge ? badge : ""}
    </div>
};

const ActionSysMsg = (props) => {
    const {
        isReminder, // used for 3 msg reminder of provider
        loading,
        role, // between provider & customer
        txHasOffer, // true if provider has made any offer
        customerName,
        providerName,
        isInquiryAccepted,
        inquiryDataAvailable, // true if inquiry with date, and false if inquiry with message
        onAcceptInquiry, // provider action
        onDeclineInquiry, // provider action
        onCreateOffer, // provider action
        onUploadOffer, // provider action
        onEditInquiry, // customer action
        onConfirmInquiry, // customer action
        onCancelInquiry, // customer action
    } = props;

    const isProvider = role == "provider";
    let paragraphMsgId = "";

    // remove confirm booking for customer if offer is created after
    if (!isProvider && isInquiryAccepted && txHasOffer) {
        return null
    };

    if (isProvider && isReminder) {
        paragraphMsgId = "ActivityFeed.sysMsg.provider.thirdMsgReminder";
    } else if (isProvider && isInquiryAccepted) {
        paragraphMsgId = "ActivityFeed.sysMsg.provider.inquiryAccepted";
    } else if (isProvider) {
        if (inquiryDataAvailable) {
            paragraphMsgId = "ActivityFeed.sysMsg.provider.inquiry";
        } else {
            paragraphMsgId = "ActivityFeed.sysMsg.provider.inquiryNoDates";
        };
    } else if (isInquiryAccepted) {
        paragraphMsgId = "ActivityFeed.sysMsg.customer.inquiryAccepted";
    } else {
        paragraphMsgId = "ActivityFeed.sysMsg.customer.inquiry";
    };

    return <div className={css.sysMsgRoot}>
        {isReminder && isProvider ? <div className={css.sysMsgSubtitle}>
            <InfoIcon /><span style={{ marginLeft: '15px' }}>
                Need more options? Create an <span style={{ color: '#30A64A' }}>individual offer</span> for your guest!
            </span>
        </div> : null}
        <div className={css.sysMsgParagraph}>
            <FormattedMessage
                id={paragraphMsgId}
                values={{ customerName, providerName }}
            />
        </div>
        <div
            className={css.sysMsgAcions}
            style={loading ? { visibility: "hidden", height: "0" } : {}}
        >
            {/* ********************************* */}
            {isReminder && isProvider ? <>
                {/* ********************************* */}
                <GeneralActionButton
                    type="confirm"
                    textId="ActivityFeed.sysMsg.providerAction.createOffer"
                    onClick={onCreateOffer}
                />

                <GeneralActionButton
                    type="confirmInverted"
                    textId="ActivityFeed.sysMsg.providerAction.uploadOffer"
                    onClick={onUploadOffer}
                    badge={<Tooltip
                        messageId="ActivityFeed.sysMsg.providerAction.uploadOfferBadgeTooltip"
                        icon={<img src={premiumBadge} style={{width: '20px', marginLeft: "-10px"}} />}
                    />}
                />
                {/* ********************************* */}
            </> : isProvider ? <>
                {/* ********************************* */}
                {isInquiryAccepted || txHasOffer || !inquiryDataAvailable ? null : <GeneralActionButton
                    type="success"
                    icon={<TickIcon />}
                    textId="ActivityFeed.sysMsg.providerAction.acceptInquiry"
                    onClick={onAcceptInquiry}
                />}

                {isInquiryAccepted ? null : <GeneralActionButton
                    type="decline"
                    icon={<XIcon />}
                    textId="ActivityFeed.sysMsg.providerAction.declineInquiry"
                    onClick={onDeclineInquiry}
                />}

                <GeneralActionButton
                    type="function"
                    icon={<PlusIcon />}
                    textId="ActivityFeed.sysMsg.providerAction.createOffer"
                    onClick={onCreateOffer}
                />
                {/* ********************************* */}
            </> : <>
                {/* ********************************* */}
                {isInquiryAccepted ? <GeneralActionButton
                    type="confirm"
                    textId="ActivityFeed.sysMsg.customerAction.confirmBooking"
                    onClick={onConfirmInquiry}
                /> : txHasOffer ? null : <GeneralActionButton
                    type="function"
                    icon={<PencilIcon />}
                    textId="ActivityFeed.sysMsg.customerAction.editInquiry"
                    onClick={onEditInquiry}
                />}

                <GeneralActionButton
                    type="decline"
                    icon={<CircleXIcon />}
                    textId="ActivityFeed.sysMsg.customerAction.cancelInquiry"
                    onClick={onCancelInquiry}
                />
            </>}
        </div>
        {loading && <IconSpinner />}
    </div>
};

const StaticSysMsg = (props) => {
    const {
        sysMsg,
        role, // between provider & customer
        customerName,
        contactDetails,
    } = props;

    const {
        type, // between declined & accepted
        decline_reason,
    } = sysMsg;

    const {
        name, phone, address,
    } = contactDetails || {};

    const isAccepted = type == "accepted";

    return <div className={css.sysMsgRoot}>
        <div className={css.sysMsgParagraph}>
            <div className={css.sysMsgSubtitle}>
                {isAccepted ? <div>
                    <GreyCircleTick /><span style={{ marginLeft: '15px' }}>
                        Yay! The booking is now <span style={{ color: '#30A64A' }}>confirmed</span>!
                    </span>
                </div> : <div>
                    <FormattedMessage
                        id={role == "provider" ? "ActivityFeed.sysMsg.providerOfferDeclined" : "ActivityFeed.sysMsg.customerOfferDeclined"}
                        values={{ customerName, coloredDeclined: <span style={{ color: '#D10000' }}>declined</span> }}
                    />
                </div>}
            </div>

            <div className={css.sysMsgMiddleText}>
                {isAccepted ? <span>
                    Please check your inbox for all details.
                </span> : decline_reason ? <span>
                    {role == "provider" ? <span>
                        The reason provided is: {decline_reason}. If you need to make adjustments or create a new offer, please feel free to do so.
                    </span> : <span>
                        Reason: {decline_reason}
                    </span>}
                </span> : null}
            </div>

            {contactDetails ? <div style={{ marginTop: "15px" }}>
                {name && <div>{name} Contact Details</div>}
                {address && <div><strong>Address:</strong> {address}</div>}
                {phone && <div>
                    <strong>Mobile:</strong> <a href={`tel:${phone}`}>{phone}</a>
                </div>}
            </div> : null}
        </div>
    </div>
};

const SysMsgComponent = (props) => {
    const { sysMsgType, errorMsg, ...rest } = props;
    return <div>
        {sysMsgType == 'action' && <ActionSysMsg {...rest} />}
        {sysMsgType == 'static' && <StaticSysMsg {...rest} />}
        {errorMsg && <div style={{ color: 'red' }}>{errorMsg}</div>}
    </div>
};

export default SysMsgComponent;
