import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
    TRANSITION_ENQUIRE,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    txIsAccepted,
    txIsCanceled,
    txIsDeclined,
    txIsEnquired,
    txIsPaymentExpired,
    txIsPaymentPending,
    txIsRequested,
    txHasBeenDelivered,
    TRANSITION_INSTANT_BOOKING,
    TRANSITION_ACCEPT_FILE_OFFER,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import {
    ensureListing,
    ensureTransaction,
    ensureUser,
    userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import { getValidOffer, attachTimeToDate } from '../../util/misc';
import {
    AvatarLarge,
    BookingPanel,
    Button,
    NamedLink,
    ReviewModal,
    UserDisplayName,
    Modal,
    IconClose,
    IconEdit,
    InfoBox,
} from '../../components';
import ProviderInquiryActions from './ProviderInquiryActions/ProviderInquiryActions';
import { SendMessageForm } from '../../forms';
import EstimatedBreakdownMaybe from '../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
import config from '../../config';
import { openBookModal, closeBookModal } from '../BookingPanel/BookingPanel';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
    HEADING_ENQUIRED,
    HEADING_PAYMENT_PENDING,
    HEADING_PAYMENT_EXPIRED,
    HEADING_REQUESTED,
    HEADING_ACCEPTED,
    HEADING_DECLINED,
    HEADING_CANCELED,
    HEADING_DELIVERED,
} from './PanelHeading';

import OfferModal from './OfferModal/OfferModal';

import css from './TransactionPanel.module.css';
import IconReturn from './IconReturn';
import { getIntlTypeOfChargeOptionLabel, TYPE_OF_CHARGE_OPTIONS } from "../../data/typeOfCharges"
import CancelInquiryModal from '../CancelInquiryModal/CancelInquiryModal';


// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
    const authorDisplayName = (
        <UserDisplayName user={currentProvider} intl={intl} />
    );
    const customerDisplayName = (
        <UserDisplayName user={currentCustomer} intl={intl} />
    );

    let otherUserDisplayName = '';
    let otherUserDisplayNameString = '';
    const currentUserIsCustomer =
        currentUser.id &&
        currentCustomer.id &&
        currentUser.id.uuid === currentCustomer.id.uuid;
    const currentUserIsProvider =
        currentUser.id &&
        currentProvider.id &&
        currentUser.id.uuid === currentProvider.id.uuid;

    if (currentUserIsCustomer) {
        otherUserDisplayName = authorDisplayName;
        otherUserDisplayNameString = userDisplayNameAsString(
            currentProvider,
            ''
        );
    } else if (currentUserIsProvider) {
        otherUserDisplayName = customerDisplayName;
        otherUserDisplayNameString = userDisplayNameAsString(
            currentCustomer,
            ''
        );
    }

    return {
        authorDisplayName,
        customerDisplayName,
        otherUserDisplayName,
        otherUserDisplayNameString,
    };
};

export class TransactionPanelComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendMessageFormFocused: false,
            isReviewModalOpen: false,
            reviewSubmitted: false,
            isDeleteOpen: false,
            formValues: {},
            showInquiryForm: this.props.location.state?.showInquiryForm,
            isOfferModalOpen: false,
            offerModalType: 'default',
            idOfOfferBeingUpdated: null,
            cancelInquiryModalType: 'default',
            isCancelInquiryModalOpen: false,
            customLineItemsNeededSource: null,
            customLineItemsCurrentSource: null,
        };
        this.isMobSaf = false;
        this.sendMessageFormName = 'TransactionPanel.SendMessageForm';
        this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
        this.onSubmitReview = this.onSubmitReview.bind(this);
        this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
        this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
        this.onMessageSubmit = this.onMessageSubmit.bind(this);
        this.scrollToMessage = this.scrollToMessage.bind(this);
        this.onDeleteModal = this.onDeleteModal.bind(this);
    }

    componentDidMount() {
        this.isMobSaf = isMobileSafari();
    }

    componentDidUpdate() {
        const {
            transaction,
            currentUser,
            offers,
            lineItems,
            fetchLineItemsInProgress,
            onFetchTransactionLineItems,
            fetchOffersInProgress,
            sendOfferInProgress,
        } = this.props;

        const isInquiry = transaction.attributes.lastTransition == TRANSITION_ENQUIRE;

        const {
            customLineItemsNeededSource,
            customLineItemsCurrentSource,
        } = this.state;

        if (
            fetchLineItemsInProgress ||
            fetchOffersInProgress ||
            sendOfferInProgress ||
            !isInquiry
        ) return

        const noLineItems = !lineItems;
        const noCurrentSource = !customLineItemsCurrentSource;
        const differentSource = customLineItemsNeededSource !== customLineItemsCurrentSource;

        const validOffer = getValidOffer(offers);
        const validOfferButNotUpdated = validOffer && customLineItemsCurrentSource !== validOffer._id;

        if (noLineItems || noCurrentSource || differentSource || validOfferButNotUpdated) {
            const rawBookingData = transaction.attributes.metadata.rawBookingData || {};
            const {
                activity,
                noOfGuest,
                bookingStartTime,
                bookingEndTime,
                addOns,
            } = rawBookingData;

            if (!validOffer && !bookingStartTime) return

            const bookingData = validOffer ? {
                startDate: attachTimeToDate(new Date(validOffer.startDate), validOffer.startTime),
                endDate: attachTimeToDate(new Date(validOffer.endDate), validOffer.endTime),
                noOfGuest: validOffer.noOfGuests,
                addOns: validOffer.addons,
                activity: activity,
                removeFee: validOffer.disableRentalFee,
                allAddons: validOffer.addons,
                offerPrice: validOffer.totalPrice,
                offerType: validOffer.offerType,
            } : {
                startDate: new Date(Number(bookingStartTime)),
                endDate: new Date(Number(bookingEndTime)),
                noOfGuest: noOfGuest,
                // might need to pass quantity to addons
                addOns: addOns ? Object.keys(addOns).map(a => {
                    return {
                        uuid: a
                    }
                }) : [],
                activity: activity,
                removeFee: false,
                specialAddOns: [],
            };

            const listingId = transaction.listing.id;
            const isOwnListing = transaction.listing.author.id.uuid == currentUser.id.uuid;

            this.setState({
                customLineItemsNeededSource: validOffer ? validOffer._id : "rawData",
                customLineItemsCurrentSource: validOffer ? validOffer._id : "rawData",
            });
            onFetchTransactionLineItems({
                bookingData,
                listingId,
                isOwnListing,
            });
        };
    }

    onOpenReviewModal() {
        this.setState({ isReviewModalOpen: true });
    }

    onDeleteModal(state) {
        this.setState({ isDeleteOpen: state });
    }
    onSubmitReview(values) {
        const { onSendReview, transaction, transactionRole } = this.props;
        const currentTransaction = ensureTransaction(transaction);
        const { reviewRating, reviewContent } = values;
        const rating = Number.parseInt(reviewRating, 10);
        onSendReview(transactionRole, currentTransaction, rating, reviewContent)
            .then((r) =>
                this.setState({
                    isReviewModalOpen: false,
                    reviewSubmitted: true,
                })
            )
            .catch((e) => {
                // Do nothing.
            });
    }

    onSendMessageFormFocus() {
        this.setState({ sendMessageFormFocused: true });
        if (this.isMobSaf) {
            // Scroll to bottom
            window.scroll({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    onSendMessageFormBlur() {
        this.setState({ sendMessageFormFocused: false });
    }

    onMessageSubmit(values, form, reload) {
        const message = values.message ? values.message.trim() : null;
        const { transaction, onSendMessage } = this.props;
        const ensuredTransaction = ensureTransaction(transaction);

        if (!message) {
            return;
        }
        onSendMessage(ensuredTransaction.id, message)
            .then((messageId) => {
                if (reload) {
                    if (typeof window !== "undefined") { window.location.reload() }
                }
                form.reset();
                this.scrollToMessage(messageId);
            })
            .catch((e) => {
                // Ignore, Redux handles the error
            });
    }

    scrollToMessage(messageId) {
        const selector = `#msg-${messageId.uuid}`;
        const el = document.querySelector(selector);
        if (el) {
            el.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    }

    render() {
        const {
            rootClassName,
            className,
            currentUser,
            transaction,
            totalMessagePages,
            oldestMessagePageFetched,
            messages,
            initialMessageFailed,
            savePaymentMethodFailed,
            fetchMessagesInProgress,
            fetchMessagesError,
            sendMessageInProgress,
            sendMessageError,
            sendReviewInProgress,
            sendReviewError,
            onFetchTimeSlots,
            onManageDisableScrolling,
            onShowMoreMessages,
            transactionRole,
            intl,
            onAcceptSale,
            onDeclineSale,
            acceptInProgress,
            declineInProgress,
            acceptSaleError,
            declineSaleError,
            onSubmitBookingRequest,
            monthlyTimeSlots,
            nextTransitions,
            onFetchTransactionLineItems,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            specialAddOns,
            onEditInquiry,
            history,
            location: reactLocation,
            viewport,
            fetchOffersInProgress,
            fetchOffersError,
            offers,
            sendOfferInProgress,
            sendOfferError,
            onSendOffer,
            onUpdateOffer,
            onAcceptFileOffer,
            onHandleG4,
            sendFileInProgress,
            sendFileError,
            onUploadFile,
            files,
            tempFileId,
            sysMsgs,
        } = this.props;

        const currentTransaction = ensureTransaction(transaction);
        const currentListing = ensureListing(currentTransaction.listing);
        const currentProvider = ensureUser(currentTransaction.provider);
        const currentCustomer = ensureUser(currentTransaction.customer);
        const isCustomer = transactionRole === 'customer';
        const isProvider = transactionRole === 'provider';
        const savedBookingData = currentTransaction.attributes.metadata.rawBookingData || {};
        const isInquiryAccepted = currentTransaction.attributes.metadata.inquiryAccepted;

        const isInquiry = transaction.attributes.lastTransition == TRANSITION_ENQUIRE;

        const hasListingState = !!currentListing.attributes.state;
        const isClosed =
            hasListingState && currentListing.attributes.state === LISTING_STATE_CLOSED;

        const isMobileLayout = viewport.width <= 1023;

        const listingLoaded = !!currentListing.id;
        const listingDeleted =
            listingLoaded && currentListing.attributes.deleted;
        const iscustomerLoaded = !!currentCustomer.id;
        const isCustomerBanned =
            iscustomerLoaded && currentCustomer.attributes.banned;
        const isCustomerDeleted =
            iscustomerLoaded && currentCustomer.attributes.deleted;
        const isProviderLoaded = !!currentProvider.id;
        const isProviderBanned =
            isProviderLoaded && currentProvider.attributes.banned;
        const isProviderDeleted =
            isProviderLoaded && currentProvider.attributes.deleted;

        const stateDataFn = (tx) => {
            if (txIsEnquired(tx)) {
                const transitions = Array.isArray(nextTransitions)
                    ? nextTransitions.map((transition) => {
                        return transition.attributes.name;
                    })
                    : [];
                const hasCorrectNextTransition =
                    transitions.length > 0 &&
                    transitions.includes(
                        TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
                    );
                return {
                    headingState: HEADING_ENQUIRED,
                    showBookingPanel:
                        isCustomer &&
                        !isProviderBanned &&
                        hasCorrectNextTransition,
                    // showCancelButton: !isProvider,
                };
            } else if (txIsPaymentPending(tx)) {
                return {
                    headingState: HEADING_PAYMENT_PENDING,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsPaymentExpired(tx)) {
                return {
                    headingState: HEADING_PAYMENT_EXPIRED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsRequested(tx)) {
                return {
                    headingState: HEADING_REQUESTED,
                    showDetailCardHeadings: isCustomer,
                    showSaleButtons: isProvider && !isCustomerBanned,
                    // showCancelButton: !isProvider,
                };
            } else if (txIsAccepted(tx)) {
                return {
                    headingState: HEADING_ACCEPTED,
                    showDetailCardHeadings: true,
                    showAddress: false,
                };
            } else if (txIsDeclined(tx)) {
                return {
                    headingState: HEADING_DECLINED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsCanceled(tx)) {
                return {
                    headingState: HEADING_CANCELED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txHasBeenDelivered(tx)) {
                return {
                    headingState: HEADING_DELIVERED,
                    showDetailCardHeadings: true,
                    showAddress: false,
                };
            } else {
                return { headingState: 'unknown' };
            }
        };
        const stateData = stateDataFn(currentTransaction);
        const isRequestedTx = stateData?.headingState === HEADING_REQUESTED;

        const deletedListingTitle = intl.formatMessage({
            id: 'TransactionPanel.deletedListingTitle',
        });

        const {
            authorDisplayName,
            customerDisplayName,
            otherUserDisplayName,
            otherUserDisplayNameString,
        } = displayNames(currentUser, currentProvider, currentCustomer, intl);

        const { publicData, geolocation } = currentListing.attributes;
        const phoneNumber = isProvider ? currentCustomer?.attributes?.profile?.publicData?.phoneNumber : currentProvider?.attributes?.profile?.publicData?.phoneNumber
        const company = isProvider ? currentCustomer?.attributes?.profile?.publicData?.company : ''
        const location =
            publicData && publicData.location ? publicData.location : {};
        const listingTitle = currentListing.attributes.deleted
            ? deletedListingTitle
            : currentListing.attributes.title;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const unitTranslationKey = isNightly
            ? 'TransactionPanel.perNight'
            : isDaily
                ? 'TransactionPanel.perDay'
                : 'TransactionPanel.perUnit';

        const price = currentListing.attributes.price;
        const bookingSubTitle = price
            ? `${formatMoney(intl, price, 1.1)} ${intl.formatMessage({
                id: unitTranslationKey,
            })}`
            : '';

        const firstImage =
            currentListing.images && currentListing.images.length > 0
                ? currentListing.images[0]
                : null;

        const submitG4 = (type) => {
            // old g4
            // const personLineItem =
            //     currentTransaction?.attributes.lineItems.find((line) =>
            //         line.code.includes('per person')
            //     );
            // const quantity = personLineItem ? personLineItem.quantity.d[0] : '';
            // const findAddOns = currentTransaction?.attributes.lineItems.filter(
            //     (line) => line.code.includes('/addOn:')
            // );
            // const addOns = findAddOns.map((line) => {
            //     return line.code.substring(17);
            // });

            // const formattedTotalPrice = formatMoney(
            //     intl,
            //     currentTransaction?.attributes?.payoutTotal
            // ).substring(4);

            // window.dataLayer.push({
            //     event:
            //         type == 'accept' ? 'booking_accepted' : stateData.showSaleButtons ? 'booking_declined' : 'booking_cancelled',
            //     booking_id: currentTransaction?.booking?.id.uuid,
            //     transaction_id: currentTransaction?.id.uuid,
            //     space_id: currentTransaction?.listing?.id.uuid,
            //     start_date:
            //         currentTransaction?.booking?.attributes?.displayStart?.toLocaleDateString(
            //             'en-GB'
            //         ),
            //     end_date:
            //         currentTransaction?.booking?.attributes?.displayEnd?.toLocaleDateString(
            //             'en-GB'
            //         ),
            //     currency: currentTransaction?.attributes?.payinTotal?.currency,
            //     price: currentTransaction?.attributes?.payinTotal?.amount / 100,
            //     spaces: currentListing?.attributes?.title,
            //     // activities: selectedActivity?.title,
            //     no_of_guest: quantity,
            //     add_ons: addOns,
            //     host_id: currentTransaction?.listing?.author?.id?.uuid,
            //     host_name:
            //         currentTransaction?.listing?.author?.attributes?.profile
            //             ?.displayName,
            // });

            if (type == "accept") {
                onAcceptSale(currentTransaction);
            } else {
                onDeclineSale(
                    currentTransaction.id,
                    // stateData.showCancelButton
                    type == "cancel"
                )
            }
        }

        const saleButtons = (
            <SaleActionButtonsMaybe
                showButtons={
                    stateData.showSaleButtons || stateData.showCancelButton
                }
                acceptInProgress={acceptInProgress}
                declineInProgress={declineInProgress}
                acceptSaleError={acceptSaleError}
                declineSaleError={declineSaleError}
                showCancelButton={stateData.showCancelButton}
                onAcceptSale={() => submitG4('accept')}
                onDeclineSale={() => {
                    if (
                        window.confirm(
                            `Confirm ${stateData.showSaleButtons ? 'decline' : 'cancel'
                            } this booking?`
                        )
                    ) {
                        submitG4('decline');
                    }
                }}
            />
        );

        const showSendMessageForm =
            !isCustomerBanned &&
            !isCustomerDeleted &&
            !isProviderBanned &&
            !isProviderDeleted;

        const sendMessagePlaceholder = intl.formatMessage(
            { id: 'TransactionPanel.sendMessagePlaceholder' },
            { name: otherUserDisplayNameString }
        );

        const sendingMessageNotAllowed = intl.formatMessage({
            id: 'TransactionPanel.sendingMessageNotAllowed',
        });

        const paymentMethodsPageLink = (
            <NamedLink name="PaymentMethodsPage">
                <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
            </NamedLink>
        );
        const classes = classNames(rootClassName || css.root, className);

        const translationKey = isNightly
            ? 'EditListingAddOnForm.pricePerNight'
            : isDaily
                ? 'EditListingAddOnForm.pricePerDay'
                : 'EditListingAddOnForm.pricePerUnit';

        const addOnTitle = this.props.transaction.attributes.metadata.specialAddOns ? this.props.transaction.attributes.metadata.specialAddOns.map(i => i.title).join(', ') : null;

        const rawBookingData = transaction.attributes.metadata.rawBookingData;

        const aValidOffer = getValidOffer(offers);
        const currentNumberOfGuests = aValidOffer ? aValidOffer.noOfGuests : rawBookingData ? rawBookingData.noOfGuest : "Unknown";

        const getCorrectDates = () => {
            const areDatesRaw = this.state.customLineItemsCurrentSource == "rawData";
            const foundOffer = !areDatesRaw && this.state.customLineItemsCurrentSource ? offers.find(o => o._id == this.state.customLineItemsCurrentSource) : null;

            if (areDatesRaw && rawBookingData && rawBookingData.bookingStartTime) {
                return {
                    startDate: new Date(Number(rawBookingData.bookingStartTime)),
                    endDate: new Date(Number(rawBookingData.bookingEndTime)),
                };
            } else if (foundOffer) {
                return {
                    startDate: attachTimeToDate(new Date(foundOffer.startDate), foundOffer.startTime),
                    endDate: attachTimeToDate(new Date(foundOffer.endDate), foundOffer.endTime),
                    tax: foundOffer.tax,
                };
            } else {
                return {
                    startDate: new Date(),
                    endDate: new Date(),
                };
            };
        };

        const paidFileOffer = offers.find(o => o.offerStatus == "accepted" && o.offerType == "file");
        const paidTax = paidFileOffer ? paidFileOffer.tax : null;

        const { startDate, endDate, tax } = getCorrectDates();
        const timeZone = transaction.listing.attributes.availabilityPlan &&
            transaction.listing.attributes.availabilityPlan.timezone;
        const bookingData = startDate && endDate ? {
            unitType,
            startDate,
            endDate,
            timeZone,
            tax,
        } : null;

        const txIsSupported = transaction.attributes.processVersion >= config.minTxSupportedVersion;

        const CustomBreakdownComponent = () => {
            const showStaticBreakdown = !this.state.showInquiryForm && isInquiry;
            return showStaticBreakdown ? <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                removeFee={false}
                userRole={transactionRole}
                className={isProvider ? css.customBreakDownProvider : css.customBreakDownCustomer}
            /> : <></>
        };

        const txIsCancelled = txIsCanceled(transaction);
        const CustomBadgeComponent = (props) => {
            const { style } = props;

            const offerInUse = this.state.customLineItemsCurrentSource && this.state.customLineItemsCurrentSource !== "rawData";
            const txIsPaid = transaction?.attributes?.transitions?.find(t => t.transition == TRANSITION_INSTANT_BOOKING || t.transition == TRANSITION_ACCEPT_FILE_OFFER);

            const cbInquiryText = intl.formatMessage({
                id: 'TransactionPanel.customBadge.inquiry',
            });
            const cbCancelledText = intl.formatMessage({
                id: 'TransactionPanel.customBadge.bookingCancelled',
            });
            const cbPaidText = intl.formatMessage({
                id: 'TransactionPanel.customBadge.bookingConfirmed',
            });
            const cbOfferText = intl.formatMessage({
                id: 'TransactionPanel.customBadge.individualOffer',
            });

            let customBadgeText = "";
            let themeClassName = "";

            if (txIsPaid) {
                customBadgeText = cbPaidText;
                themeClassName = css.customBadgeDefault;
            } else if (txIsCancelled) {
                customBadgeText = cbCancelledText;
                themeClassName = css.customBadgeCancelled;
            } else if (offerInUse) {
                customBadgeText = cbOfferText;
                themeClassName = css.customBadgeDefault;
            } else if (isInquiry) {
                customBadgeText = cbInquiryText;
                themeClassName = css.customBadgeInquiry;
            };

            if (customBadgeText) {
                return <div
                    className={`${css.customBadge} ${themeClassName}`}
                    style={style ? style : {}}
                >
                    {customBadgeText}
                </div>
            } else {
                return <div />
            };
        };

        const CancellationPolicyComponent = () => {
            const lastOffer = offers[offers.length - 1];
            const offerPolicy = lastOffer ? lastOffer.cancellationPolicy : null;

            const rowActivityId = rawBookingData?.activity;
            const rowActivity = currentListing.attributes.publicData.clusters?.find(c => c.uuid == rowActivityId);
            const rowCancellationPolicy = rowActivity?.cancellationPolicy;

            const policyToTake = offerPolicy ? offerPolicy : rowCancellationPolicy;
            return <InfoBox
                title={<FormattedMessage id="TransactionPanel.cancellationPolicy" />}
                content={policyToTake}
                withDropdown={true}
            />
        };

        const toggleShowInquiryForm = () => {
            this.setState({ showInquiryForm: !this.state.showInquiryForm });
        };

        const submitEditInquiry = (values) => {
            const { bookingEndDate, bookingStartDate } = values;
            bookingStartDate.date = bookingStartDate.date.toISOString();
            bookingEndDate.date = bookingEndDate.date.toISOString();
            onEditInquiry(transaction.id, values);

            if (isMobileLayout) {
                reactLocation.state = { showInquiryForm: false };
                closeBookModal(history, reactLocation);
            } else {
                this.setState({ showInquiryForm: false });
            };
        };

        return (
            <div className={classes}>
                <div className={css.returnToInbox}>
                    <NamedLink name="InboxBasePage">
                        <IconReturn className={css.icon} />
                    </NamedLink>
                </div>

                <div className={css.container}>
                    <div className={css.txInfo}>
                        <DetailCardImage
                            rootClassName={css.imageWrapperMobile}
                            avatarWrapperClassName={css.avatarWrapperMobile}
                            listingTitle={listingTitle}
                            image={firstImage}
                            provider={currentProvider}
                            isCustomer={isCustomer}
                            listingId={
                                currentListing.id && currentListing.id.uuid
                            }
                            listingDeleted={listingDeleted}
                        />

                        {isProvider ? (
                            <div className={css.avatarWrapperProviderDesktop}>
                                <AvatarLarge
                                    user={currentCustomer}
                                    className={css.avatarDesktop}
                                />
                                <div><div style={{ fontWeight: "500", marginTop: "15px" }}>{customerDisplayName}</div>
                                    <div>{company}</div>
                                </div>


                            </div>
                        ) : null}

                        <PanelHeading
                            isRequestedTx={isRequestedTx}
                            panelHeadingState={stateData.headingState}
                            transactionRole={transactionRole}
                            providerName={authorDisplayName}
                            customerName={customerDisplayName}
                            isCustomerBanned={isCustomerBanned}
                            listingId={
                                currentListing.id && currentListing.id.uuid
                            }
                            listingTitle={listingTitle}
                            listingDeleted={listingDeleted}
                            isInquiryAccepted={isInquiryAccepted}
                        />

                        <div className={css.mobileOnly}>
                            <CustomBadgeComponent />
                        </div>

                        <div className={css.bookingDetailsMobile}>
                            <AddressLinkMaybe
                                rootClassName={css.addressMobile}
                                location={location}
                                geolocation={geolocation}
                                showAddress={stateData.showAddress}
                                phoneNumber={phoneNumber}
                            />

                            <BreakdownMaybe
                                transaction={currentTransaction}
                                transactionRole={transactionRole}
                                paidTax={paidTax}
                            />
                        </div>
                        {addOnTitle && isCustomer && isInquiry ? <>

                            <p style={{ textAlign: "center", color: "#30a64a" }}>{otherUserDisplayNameString} has created an individual offer for you. You can select it during the booking process as an add-on.</p>
                        </> : null}
                        {addOnTitle && isProvider && isInquiry ? <>

                            <p style={{ textAlign: "center", color: "#30a64a" }}>You've added an individual offer</p>
                        </> : null}

                        {savePaymentMethodFailed ? (
                            <p className={css.genericError}>
                                <FormattedMessage
                                    id="TransactionPanel.savePaymentMethodFailed"
                                    values={{ paymentMethodsPageLink }}
                                />
                            </p>
                        ) : null}
                        <FeedSection
                            rootClassName={css.feedContainer}
                            currentTransaction={currentTransaction}
                            currentUser={currentUser}
                            fetchMessagesError={fetchMessagesError}
                            fetchMessagesInProgress={fetchMessagesInProgress}
                            initialMessageFailed={initialMessageFailed}
                            messages={messages}
                            oldestMessagePageFetched={oldestMessagePageFetched}
                            onOpenReviewModal={this.onOpenReviewModal}
                            onShowMoreMessages={() =>
                                onShowMoreMessages(currentTransaction.id)
                            }
                            totalMessagePages={totalMessagePages}
                            submitG4={submitG4}
                            actionInProgress={declineInProgress || acceptInProgress}
                            actionError={declineSaleError || acceptSaleError}
                            onEditInquiry={() => {
                                if (isMobileLayout) {
                                    reactLocation.state = { showInquiryForm: true };
                                    openBookModal(false, isClosed, history, reactLocation);
                                } else {
                                    this.setState({ showInquiryForm: true });
                                };
                            }}
                            onSubmitBookingRequest={onSubmitBookingRequest}
                            customBreakdownComponent={<CustomBreakdownComponent />}
                            fetchOffersInProgress={fetchOffersInProgress}
                            fetchOffersError={fetchOffersError}
                            offers={offers}
                            tempFileId={tempFileId}
                            files={files}
                            sendFileInProgress={sendFileInProgress}
                            sendFileError={sendFileError}
                            onUploadFile={onUploadFile}
                            sysMsgs={sysMsgs}
                            sendOfferInProgress={sendOfferInProgress}
                            sendOfferError={sendOfferError}
                            onUpdateOffer={onUpdateOffer}
                            onAcceptFileOffer={onAcceptFileOffer}
                            onHandleG4={onHandleG4}
                            onSendOffer={(offerIdToUpdate) => {
                                this.setState({
                                    isOfferModalOpen: true,
                                    offerModalType: offerIdToUpdate ? offers.find(o => o._id == offerIdToUpdate).offerType : 'default',
                                    idOfOfferBeingUpdated: offerIdToUpdate,
                                })
                            }}
                            onUploadOffer={() => this.setState({
                                isOfferModalOpen: true,
                                offerModalType: 'file',
                                idOfOfferBeingUpdated: null,
                            })}
                            onCancelInquiry={() => this.setState({
                                isCancelInquiryModalOpen: true,
                                cancelInquiryModalType: 'default',
                            })}
                            inquiryDataAvailable={!!rawBookingData}
                            lineItems={lineItems}
                        />
                        {showSendMessageForm ? (
                            <div className={css.sendMessage}>
                                <SendMessageForm
                                    formId={this.sendMessageFormName}
                                    rootClassName={css.sendMessageForm}
                                    messagePlaceholder={sendMessagePlaceholder}
                                    inProgress={sendMessageInProgress}
                                    sendMessageError={sendMessageError}
                                    onFocus={this.onSendMessageFormFocus}
                                    onBlur={this.onSendMessageFormBlur}
                                    onSubmit={this.onMessageSubmit}
                                    intl={intl}
                                />
                            </div>
                        ) : (
                            <div className={css.sendingMessageNotAllowed}>
                                {sendingMessageNotAllowed}
                            </div>
                        )}

                        {stateData.showSaleButtons ||
                            stateData.showCancelButton ? (
                            <div className={css.mobileActionButtons}>
                                {saleButtons}
                            </div>

                        ) : null}
                    </div>


                    <div className={css.asideDesktop}>
                        <div className={css.detailCard}>
                            <DetailCardImage
                                avatarWrapperClassName={
                                    css.avatarWrapperDesktop
                                }
                                listingTitle={listingTitle}
                                image={firstImage}
                                provider={currentProvider}
                                isCustomer={isCustomer}
                                listingId={
                                    currentListing.id && currentListing.id.uuid
                                }
                                listingDeleted={listingDeleted}
                            />


                            <DetailCardHeadingsMaybe
                                showDetailCardHeadings={
                                    stateData.showDetailCardHeadings
                                }
                                listingTitle={listingTitle}
                                subTitle={bookingSubTitle}
                                location={location}
                                geolocation={geolocation}
                                showAddress={stateData.showAddress}
                                phoneNumber={phoneNumber}
                                authorDisplayName={authorDisplayName}
                                isProvider={isProvider}
                            />

                            <div
                                className={css.desktopOnly}
                                style={txIsCancelled ? { margin: '20px', display: 'flex', justifyContent: 'center' } : isInquiry ? { margin: isProvider ? '20px 0 0 20px' : '20px 0 0 40px' } : {}}
                            >
                                <CustomBadgeComponent />
                            </div>

                            {stateData.showBookingPanel ? (
                                <BookingPanel
                                    className={css.bookingPanel}
                                    titleClassName={css.bookingTitle}
                                    isOwnListing={false}
                                    listing={currentListing}
                                    title={listingTitle}
                                    subTitle={bookingSubTitle}
                                    authorDisplayName={authorDisplayName}
                                    // onSubmit={onSubmitBookingRequest}
                                    onSubmit={(values) => submitEditInquiry(values)}
                                    savedBookingData={savedBookingData}
                                    onManageDisableScrolling={
                                        onManageDisableScrolling
                                    }
                                    monthlyTimeSlots={monthlyTimeSlots}
                                    onFetchTimeSlots={onFetchTimeSlots}
                                    onFetchTransactionLineItems={
                                        onFetchTransactionLineItems
                                    }
                                    lineItems={lineItems}
                                    fetchLineItemsInProgress={
                                        fetchLineItemsInProgress
                                    }
                                    onMessageSubmit={this.onMessageSubmit}
                                    fetchLineItemsError={fetchLineItemsError}
                                    specialAddOns={currentTransaction?.attributes?.metadata?.specialAddOns}
                                    showInquiryForm={this.state.showInquiryForm}
                                    toggleShowInquiryForm={toggleShowInquiryForm}
                                    isInquiryAccepted={isInquiryAccepted}
                                    offerMade={offers.length > 0}
                                    customBreakdownComponent={<CustomBreakdownComponent />}
                                    customBadgeComponent={<CustomBadgeComponent
                                        style={{ marginLeft: '12px' }}
                                    />}
                                    cancellationPolicyComponent={<div className={css.cancellationPolicyMobile}>
                                        <CancellationPolicyComponent />
                                    </div>}
                                    txIsSupported={txIsSupported}
                                />
                            ) : null}

                            {isProvider && <div style={{ margin: '0' }} className={css.desktopOnly}>
                                <div className={css.noOfGuest}>
                                    <FormattedMessage id="OfferModal.noOfGuests" />: {currentNumberOfGuests}
                                </div>
                                <CustomBreakdownComponent />
                            </div>}

                            <BreakdownMaybe
                                className={css.breakdownContainer}
                                transaction={currentTransaction}
                                transactionRole={transactionRole}
                                paidTax={paidTax}
                            />

                            {stateData.showSaleButtons ||
                                stateData.showCancelButton ? (
                                <div className={css.desktopActionButtons}>
                                    {saleButtons}
                                </div>
                            ) : null}
                        </div>

                        {isCustomer && <div
                            className={`${css.desktopOnly} ${css.cancellationPolicy}`}
                        >
                            <CancellationPolicyComponent />
                        </div>}

                        {this.state.isOfferModalOpen ? <OfferModal
                            isOpen={this.state.isOfferModalOpen}
                            offerModalType={this.state.offerModalType}
                            onChangeOfferModalType={(v) => this.setState({ offerModalType: v })}
                            offerBeingUpdated={this.state.idOfOfferBeingUpdated ? offers.find(o => o._id == this.state.idOfOfferBeingUpdated) : null}
                            onClose={() => this.setState({ isOfferModalOpen: false })}
                            intl={intl}
                            timeZone={timeZone}
                            txId={currentTransaction.id}
                            currentListing={currentListing}
                            savedBookingData={savedBookingData}
                            customerName={customerDisplayName}
                            providerId={currentProvider.id}
                            onManageDisableScrolling={onManageDisableScrolling}
                            onSendOffer={(v, payin) => {
                                const fileURL = v.attachedFileId ? files.find(f => f._id == v.attachedFileId).file_URL : null;
                                onSendOffer({
                                    offerParams: v,
                                    payin,
                                    transaction: currentTransaction,
                                    intl,
                                    fileURL,
                                });
                            }}
                            onUpdateOffer={(v) => {
                                onUpdateOffer(v);
                                this.setState({ customLineItemsCurrentSource: "" });
                            }}
                            sendFileInProgress={sendFileInProgress}
                            sendFileError={sendFileError}
                            onUploadFile={onUploadFile}
                            tempFileId={tempFileId}
                        /> : null}

                        <CancelInquiryModal
                            isOpen={this.state.isCancelInquiryModalOpen}
                            cancelInquiryModalType={this.state.cancelInquiryModalType}
                            onClose={() => this.setState({ isCancelInquiryModalOpen: false })}
                            intl={intl}
                            txId={currentTransaction.id}
                            onManageDisableScrolling={onManageDisableScrolling}
                            submitG4={submitG4}
                        />

                        {isProvider && isInquiry ? <ProviderInquiryActions
                            listing={currentListing}
                            noOfGuest={currentNumberOfGuests}
                            customBadgeComponent={<CustomBadgeComponent
                                style={{ marginLeft: '12px' }}
                            />}
                            customBreakdownComponent={<CustomBreakdownComponent />}
                            intl={intl}
                            onManageDisableScrolling={onManageDisableScrolling}
                        /> : null}
                    </div>
                </div>

                <ReviewModal
                    id="ReviewOrderModal"
                    isOpen={this.state.isReviewModalOpen}
                    onCloseModal={() => this.setState({ isReviewModalOpen: false })}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onSubmitReview={this.onSubmitReview}
                    revieweeName={otherUserDisplayName}
                    reviewSent={this.state.reviewSubmitted}
                    sendReviewInProgress={sendReviewInProgress}
                    sendReviewError={sendReviewError}
                />
            </div>
        );
    }
}

TransactionPanelComponent.defaultProps = {
    rootClassName: null,
    className: null,
    currentUser: null,
    acceptSaleError: null,
    declineSaleError: null,
    fetchMessagesError: null,
    initialMessageFailed: false,
    savePaymentMethodFailed: false,
    sendMessageError: null,
    sendReviewError: null,
    monthlyTimeSlots: null,
    nextTransitions: null,
    lineItems: null,
    fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
    rootClassName: string,
    className: string,

    currentUser: propTypes.currentUser,
    transaction: propTypes.transaction.isRequired,
    totalMessagePages: number.isRequired,
    oldestMessagePageFetched: number.isRequired,
    messages: arrayOf(propTypes.message).isRequired,
    initialMessageFailed: bool,
    savePaymentMethodFailed: bool,
    fetchMessagesInProgress: bool.isRequired,
    fetchMessagesError: propTypes.error,
    sendMessageInProgress: bool.isRequired,
    sendMessageError: propTypes.error,
    sendReviewInProgress: bool.isRequired,
    sendReviewError: propTypes.error,
    onFetchTimeSlots: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    onShowMoreMessages: func.isRequired,
    onSendMessage: func.isRequired,
    onSendReview: func.isRequired,
    onSubmitBookingRequest: func.isRequired,
    monthlyTimeSlots: object,
    nextTransitions: array,

    // Sale related props
    onAcceptSale: func.isRequired,
    onDeclineSale: func.isRequired,
    acceptInProgress: bool.isRequired,
    declineInProgress: bool.isRequired,
    acceptSaleError: propTypes.error,
    declineSaleError: propTypes.error,

    // line items
    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,

    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string,
    }).isRequired,

    // from withViewport
    viewport: shape({
        width: number.isRequired,
        height: number.isRequired,
    }).isRequired,

    // from injectIntl
    intl: intlShape,
};

const TransactionPanel = compose(withRouter, withViewport, injectIntl)(TransactionPanelComponent);

export default TransactionPanel;
